import { H4 } from "../Heading/Heading";

export interface SocialBarProps {
  className?: string;
}

export function SocialBar(props: SocialBarProps) {
  return (
    <div className={props.className}>
      <div className="md:pr-4">
        <H4 className="text-base text-neutral-600 font-bold mb-3 lg:text-xl lg:mb-6">
          Ihr wollt informiert bleiben?
        </H4>
        <ul className="flex -mx-2 text-sm lg:text-base lg:-mx-4">
          {[
            {
              icon: (
                <svg
                  width="21"
                  height="17"
                  viewBox="0 0 21 17"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.302 9.049c.373 0 .758-.196 1.155-.587L19.504.49C19.15.163 18.518 0 17.606 0H2.72c-.788 0-1.34.152-1.657.455l8.09 8.007c.392.391.774.587 1.149.587zM.242 14.547 6.83 8.03.237 1.526C.08 1.809 0 2.279 0 2.935v10.2c0 .662.081 1.132.243 1.412zm2.728 1.532h14.886c.792 0 1.35-.153 1.671-.46l-6.712-6.65-.593.593c-.624.599-1.264.898-1.92.898-.66 0-1.3-.299-1.923-.898l-.593-.592-6.694 6.641c.364.312.99.468 1.878.468zm17.38-1.541c.15-.28.226-.747.226-1.402V2.935c0-.645-.076-1.105-.229-1.383l-6.565 6.48 6.568 6.506z"
                    fill="#141416"
                  />
                </svg>
              ),
              route: `/mintvernetzt#newsletter`,
              title: `Newsletter`,
            },
            {
              icon: (
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 18.073c0 .605.075 1.064.225 1.376l6.328-6.289L.195 7.594a3.07 3.07 0 0 0-.141.552A4.397 4.397 0 0 0 0 8.873v9.2zm1.006-11.66L9.18 13.59c.215.189.405.332.571.43.166.097.343.146.532.146s.37-.049.542-.146c.173-.098.363-.241.571-.43l8.155-7.168a2.433 2.433 0 0 0-.708-.347 3.302 3.302 0 0 0-1.02-.141h-6.514v4.033c0 .273-.101.51-.303.708a.994.994 0 0 1-.723.298 1 1 0 0 1-.718-.298.952.952 0 0 1-.307-.708V5.934H2.695c-.377 0-.71.045-.996.137a2.222 2.222 0 0 0-.693.341zm.127 14.16c.195.13.443.236.742.317.3.081.654.122 1.064.122h14.668c.41 0 .769-.04 1.075-.122.306-.081.556-.194.752-.337l-6.485-6.426-.703.615c-.351.3-.68.528-.986.684a2.118 2.118 0 0 1-.977.234c-.338 0-.662-.078-.971-.234a4.907 4.907 0 0 1-.982-.684l-.703-.615-6.494 6.446zm12.89-7.413 6.309 6.28c.143-.32.215-.775.215-1.367v-9.2a4.73 4.73 0 0 0-.049-.722 2.575 2.575 0 0 0-.137-.537l-6.338 5.546zM3.496 2.203l1.953 2.06c.209.21.409.29.6.245.193-.045.315-.212.367-.498l.195-.977c.97.163 1.693.51 2.168 1.04a2.7 2.7 0 0 1 .713 1.86v.245c0 .286.07.511.21.674.14.163.334.244.581.244.248 0 .443-.081.586-.244.143-.163.215-.388.215-.674v-.244c0-.71.236-1.33.708-1.86.472-.531 1.193-.878 2.163-1.04l.186.976c.052.286.175.453.37.498.196.046.398-.036.606-.244l1.944-2.06c.162-.176.224-.357.185-.543-.039-.185-.166-.327-.38-.424L14.286.094c-.273-.124-.49-.125-.65-.005-.159.12-.21.318-.15.59l.195.997a6.14 6.14 0 0 0-1.421.464 5.363 5.363 0 0 0-1.24.781c-.355.303-.601.627-.738.972-.13-.345-.374-.67-.732-.972a5.467 5.467 0 0 0-1.245-.781 6.14 6.14 0 0 0-1.421-.464L7.07.68c.06-.273.008-.47-.15-.59-.16-.121-.373-.12-.64.004L3.691 1.237c-.214.097-.341.239-.38.424-.04.186.022.367.185.542z"
                    fill="#141416"
                  />
                </svg>
              ),
              route: `/mintvernetzt-informiert/#verteiler`,
              title: `MINTVerteiler`,
            },
          ].map((link, index) => (
            <li
              key={`nav-item-${index}`}
              className="px-2 flex-1/2 md:flex-initial mb-4 md:mb-0 items-center lg:px-4"
            >
              <a
                className="flex items-center"
                key={link.title}
                href={link.route}
              >
                {link.icon}
                <span className="ml-3">{link.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-2 md:mt-0 md:pl-4">
        <H4 className="text-base text-neutral-600 font-bold mb-3 lg:text-xl lg:mb-6">
          Hier sind wir auch noch zu finden
        </H4>
        <ul className="flex text-sm flex-wrap md:flex-nowrap -mx-2 lg:text-base lg:-mx-4">
          {[
            {
              icon: (
                <svg
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.539 20c9.057 0 14.011-7.696 14.011-14.36 0-.214 0-.433-.009-.648A10.174 10.174 0 0 0 24 2.373a9.793 9.793 0 0 1-2.834.797A5.057 5.057 0 0 0 23.337.375c-.968.588-2.026 1-3.13 1.22-1.527-1.667-3.954-2.076-5.92-.997-1.966 1.079-2.98 3.376-2.476 5.603C7.854 5.997 4.165 4.08 1.662.926c-1.303 2.308-.635 5.258 1.527 6.74A4.89 4.89 0 0 1 .96 7.04v.07c.003 2.402 1.653 4.47 3.948 4.95-.423.12-.86.179-1.298.177a4.728 4.728 0 0 1-.92-.088c.645 2.057 2.494 3.465 4.6 3.503A9.726 9.726 0 0 1 0 17.746 13.762 13.762 0 0 0 7.539 20z" />
                </svg>
              ),
              url: `https://twitter.com/mintvernetzt`,
              title: `Twitter`,
              target: `_blank`,
            },
            {
              icon: (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.054.072C5.776.132 4.905.333 4.14.63c-.8.301-1.526.774-2.125 1.385A5.89 5.89 0 0 0 .63 4.14c-.297.762-.5 1.635-.558 2.91C.015 8.332 0 8.74 0 12.002c0 3.257.015 3.665.072 4.945.06 1.278.261 2.15.558 2.913a5.894 5.894 0 0 0 1.385 2.125 5.856 5.856 0 0 0 2.123 1.385c.766.297 1.636.5 2.913.558C8.332 23.985 8.742 24 12 24c3.26 0 3.666-.015 4.947-.072 1.277-.06 2.151-.261 2.915-.558a5.874 5.874 0 0 0 2.123-1.385 5.894 5.894 0 0 0 1.385-2.125c.296-.764.498-1.635.558-2.913.057-1.28.072-1.688.072-4.947 0-3.26-.015-3.668-.072-4.949-.06-1.276-.262-2.149-.558-2.911a5.889 5.889 0 0 0-1.385-2.125A5.866 5.866 0 0 0 19.86.63c-.765-.297-1.638-.5-2.915-.558C15.665.015 15.258 0 11.997 0 8.743 0 8.334.015 7.055.072zm3.87 2.091h1.078c3.204 0 3.583.01 4.847.069 1.17.052 1.806.249 2.23.412.559.218.96.479 1.38.899.42.42.679.819.896 1.38.165.421.36 1.058.413 2.228.058 1.264.07 1.643.07 4.846 0 3.203-.012 3.584-.07 4.848-.053 1.17-.249 1.805-.413 2.228-.192.52-.499.992-.898 1.378-.42.42-.819.68-1.38.897-.42.165-1.056.36-2.228.414-1.264.057-1.643.07-4.848.07-3.204 0-3.585-.013-4.849-.07-1.17-.054-1.804-.249-2.228-.414a3.717 3.717 0 0 1-1.38-.897 3.72 3.72 0 0 1-.9-1.38c-.163-.422-.36-1.058-.412-2.228-.057-1.264-.069-1.643-.069-4.849 0-3.204.012-3.582.069-4.846.054-1.17.249-1.806.414-2.23.218-.559.478-.96.899-1.38a3.7 3.7 0 0 1 1.38-.896c.423-.165 1.057-.36 2.227-.414 1.107-.052 1.536-.067 3.772-.068v.003zm7.483 1.992a1.44 1.44 0 1 0 0 2.88 1.44 1.44 0 0 0 0-2.88zm-6.405 1.683a6.163 6.163 0 1 0-.193 12.325 6.163 6.163 0 0 0 .193-12.325zm0 2.162a4 4 0 1 1 0 8 4 4 0 0 1 0-8z" />
                </svg>
              ),
              url: `https://www.instagram.com/mintvernetzt/`,
              title: `Instagram`,
              target: `_blank`,
            },
            {
              icon: (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 1.719C0 .769.789 0 1.762 0h20.476C23.21 0 24 .77 24 1.719v20.562c0 .95-.789 1.719-1.762 1.719H1.762C.79 24 0 23.23 0 22.281V1.719zm7.415 18.372V9.253H3.813v10.838h3.602zm-1.8-12.318c1.255 0 2.037-.831 2.037-1.872-.023-1.063-.78-1.872-2.014-1.872-1.232 0-2.038.81-2.038 1.872 0 1.041.781 1.872 1.99 1.872h.024zm7.361 12.318v-6.053c0-.324.024-.648.12-.879.26-.646.852-1.317 1.848-1.317 1.304 0 1.824.993 1.824 2.451v5.798h3.602v-6.216c0-3.33-1.776-4.878-4.146-4.878-1.911 0-2.768 1.05-3.248 1.79v.037h-.024l.024-.037V9.252h-3.6c.046 1.018 0 10.838 0 10.838h3.6z" />
                </svg>
              ),
              url: `https://www.linkedin.com/company/mintvernetzt`,
              title: `LinkedIn`,
              target: `_blank`,
            },
            {
              icon: (
                <svg
                  width="24"
                  height="18"
                  viewBox="0 0 24 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.077 0h.134c1.233.005 7.48.053 9.165.537 1.037.3 1.845 1.166 2.123 2.274.151.608.258 1.414.33 2.245l.015.167.033.416.012.167c.097 1.464.11 2.834.111 3.134v.12c-.002.31-.015 1.775-.123 3.3l-.012.167-.014.167c-.075.916-.186 1.826-.352 2.495-.277 1.108-1.086 1.975-2.123 2.274-1.74.5-8.354.535-9.27.537h-.213c-.464 0-2.38-.01-4.391-.083l-.255-.01-.13-.006-.257-.012-.257-.01c-1.665-.08-3.25-.206-3.98-.417-1.037-.3-1.846-1.165-2.123-2.273-.167-.668-.278-1.579-.353-2.495l-.012-.168-.012-.167A53.673 53.673 0 0 1 0 9.1v-.197c.003-.345.015-1.535.096-2.848l.01-.165.005-.083.012-.167.033-.416.015-.167c.072-.831.179-1.638.33-2.245C.778 1.703 1.587.836 2.624.537 3.354.328 4.94.2 6.604.12L6.86.11 7.118.1l.129-.005.256-.01C8.931.033 10.36.006 11.788.001h.29V0zM9.601 5.141v7.716l6.235-3.856-6.235-3.86z" />
                </svg>
              ),
              url: `https://www.youtube.com/channel/UCVQSAkscPmmPmjYWDVuvQYg`,
              title: `Youtube`,
              target: `_blank`,
            },
          ].map((link, index) => (
            <li
              key={`nav-item-${index}`}
              className="px-2 flex-1/2 md:flex-initial mb-4 md:mb-0 items-center lg:px-4"
            >
              <a
                className="flex items-center"
                key={link.title}
                href={link.url}
                target={link.target}
              >
                {link.icon}
                <span className="ml-3">{link.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SocialBar;
